// Use this for Local Development

// export const WEBSITE_URL = "https://barishaldeliveries.com";
// export const WEBSITE_URL = "https://localhost/foodia";
// export const WEBSITE_URL = "https://foodbaria.com";

// Comment out the above and Uncomment the below line before building the files for Production
export const WEBSITE_URL = "https://" + window.location.hostname;


